import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPaths } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ConfigService1 } from './app.config.service';
import { saveAs } from 'file-saver';
import { AppConst } from '../../../assets/shared.constant';

@Injectable({
  providedIn: 'root',
})
export class AccessRequestService {
  baseUrl: string | undefined;
  pageChange = new BehaviorSubject(1);
  accessToken: any;

  constructor(private httpClient: HttpClient) {}

  searchAppData(
    pageNumber: number,
    pageSize: number,
    accessToken: any,
    searchValue?: string
  ) {
    let url = `${AppConst.baseURL}${ApiPaths.APPLICATION_ACCESS_REQUEST_ALL}`;

    let params = new HttpParams();
    params = params.append('query', searchValue ? searchValue : '');
    params = params.append('currentPage', pageNumber);
    params = params.append('pageSize', pageSize);
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}`, observe: 'body' },
      params,
    });
  }

  pendingApprovalAccessRequestList(
    pageNumber: number,
    pageSize: number,
    accessToken: any
  ) {
    let url = `${AppConst.baseURL}${ApiPaths.APPLICATION_ACCESS_REQUEST_PENDING}`;

    let params = new HttpParams();
    params = params.append('currentPage', pageNumber);
    params = params.append('pageSize', pageSize);
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params,
    });
  }
  renewalApprovalAccessRequestList(
    renewalDays: number,
    pageNumber: number,
    pageSize: number,
    accessToken: any
  ) {
    let url = `${AppConst.baseURL}${ApiPaths.APPLICATION_ACCESS_REQUEST_RENEWAL}`;

    let params = new HttpParams();
    params = params.append('currentPage', pageNumber);
    params = params.append('pageSize', pageSize);
    return this.httpClient.get<any>(url + renewalDays, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params,
    });
  }

  getApplicationRequestCommentsAll(requestID: string, accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.APPLICATION_ACCESS_REQUEST_COMMENTS_ALL}`;
    return this.httpClient.get<any>(url + requestID, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  getSingleRequestData(requestID: any, accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.APPLICATION_ACCESS_REQUEST_REQUESTID}`;
    return this.httpClient.get<any>(url + requestID, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  getCMDBAutocomplete(accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.GET_CMDB_AUTOCOMPLETE}`;
    return this.httpClient.get<any>(url + 10, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  saveRequest(payload: any, accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.SAVE_UPDATE_REQUEST}`;
    return this.httpClient.post<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  updateRequest(payload: any, accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.SAVE_UPDATE_REQUEST}`;
    return this.httpClient.patch<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  getFieldCategories(accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.GET_FIELD_CATEGORIES}`;
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  getDataScope(accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.GET_DATA_SCOPE}`;
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  addComments(accessRequestId: any, payload: any, accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.ADD_COMMENTS}`;
    return this.httpClient.post<any>(url + accessRequestId, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  getNewApplicationAccessRequest(accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.APPLICATION_ACCESS_NEW_REQUEST}`;
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  searchCmdbId(searchKeyword: string, accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.SEARCH_CMDB_ID}`;
    return this.httpClient.get<any>(url + searchKeyword, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  getStatistics(tokenResponse: any) {
    let email = tokenResponse.account.username;
    let validDay = 300;

    let url = `${AppConst.baseURL}${ApiPaths.GET_STATISTICS}`;
    return this.httpClient.get<any>(url + email + '/pendingDays/' + validDay, {
      headers: { Authorization: `Bearer ${tokenResponse.accessToken}` },
    });
  }

  getAdminAccessCotrol(accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.ADMIN_ACCESS_CONTROL_ALL_DATA}`;
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  addEmployeeToRole(accessToken: any, payload: any) {
    let url = `${AppConst.baseURL}${ApiPaths.ADMIN_ACCESS_CONTROL_ADD_DATA}`;
    return this.httpClient.post<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  deleteEmployeeToRole(accessToken: any, payloadDelete: any) {
    let payload = payloadDelete;

    let httpOptions = {
      headers: { Authorization: `Bearer ${accessToken}` },
      body: payload,
    };

    let url = `${AppConst.baseURL}${ApiPaths.ADMIN_ACCESS_CONTROL_ADD_DATA}`;
    return this.httpClient.delete<any>(url, httpOptions);
  }

  getApproverLevelData(accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.GET_APPROVER_LEVEL_DATA}`;
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  getApproverDataScopeMapping(accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.GET_APPROVER_DATA_SCOPE_MAPPING}`;
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  deleteApproverLevelData(accessToken: any, approverLevelID: number) {
    let payload = { approverLevelID: approverLevelID };

    let httpOptions = {
      headers: { Authorization: `Bearer ${accessToken}` },
      body: payload,
    };
    let url = `${AppConst.baseURL}${ApiPaths.DELETE_APPROVER_LEVEL_DATA}`;
    return this.httpClient.delete<any>(url, httpOptions);
  }

  editApproverLevelData(accessToken: any, payload: number) {
    let url = `${AppConst.baseURL}${ApiPaths.EDIT_APPROVER_LEVEL_DATA}`;
    return this.httpClient.post<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  deleteApproverDataScopeMapping(
    accessToken: any,
    approverDataScopeMappingID: number
  ) {
    let payload = { approverDataScopeMappingID: approverDataScopeMappingID };

    let httpOptions = {
      headers: { Authorization: `Bearer ${accessToken}` },
      body: payload,
    };
    let url = `${AppConst.baseURL}${ApiPaths.DELETE_APPROVER_DATA_SCOPE_MAPPING}`;
    return this.httpClient.delete<any>(url, httpOptions);
  }

  editApproverDataScopeMapping(accessToken: any, payload: number) {
    let url = `${AppConst.baseURL}${ApiPaths.EDIT_APPROVER_DATA_SCOPE_MAPPING}`;
    return this.httpClient.patch<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
  addApproverDataScopeMapping(accessToken: any, payload: number) {
    let url = `${AppConst.baseURL}${ApiPaths.ADD_APPROVER_DATA_SCOPE_MAPPING}`;
    return this.httpClient.post<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  getApprovalLevelSequence(accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.GET_APPROVAL_LEVEL_SEQUENCE}`;
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  setActiveInactiveSequence(accessToken: any, payload: any) {
    let url = `${AppConst.baseURL}${ApiPaths.EDIT_APPROVAL_LEVEL_SEQUENCE}`;
    return this.httpClient.patch<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  addApprovalLevelSequence(accessToken: any, payload: any) {
    let url = `${AppConst.baseURL}${ApiPaths.ADD_APPROVAL_LEVEL_SEQUENCE}`;
    return this.httpClient.post<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  searchEmployee(
    pageNumber: number,
    pageSize: number,
    accessToken: any,
    searchValue?: any
  ) {
    let url = `${AppConst.baseURL}${ApiPaths.SEARCH_EMPLOYEE}`;

    let params = new HttpParams();
    params = params.append('query', searchValue ? searchValue : '');
    params = params.append('currentPage', pageNumber);
    params = params.append('pageSize', pageSize);

    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params,
    });
  }

  approveRejectRequest(accessToken: any, payload: any) {
    let url = `${AppConst.baseURL}${ApiPaths.APPROVE_REJECT_REQUEST}`;
    return this.httpClient.post<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  downloadFile(link: any) {
    const fileName = link.split('/').pop().split('?')[0];
    this.httpClient
      .get(link, { responseType: 'blob' })
      .subscribe((data: Blob) => {
        saveAs(data, fileName);
      });
  }

  getAndSearchApprovers(
    pageNumber: number,
    pageSize: number,
    accessToken: any,
    searchValue?: any
  ) {
    let url = `${AppConst.baseURL}${ApiPaths.GET_ALL_APPROVERS}`;

    let params = new HttpParams();
    params = params.append('query', searchValue ? searchValue : '');
    params = params.append('currentPage', pageNumber);
    params = params.append('pageSize', pageSize);

    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params,
    });
  }

  deleteApprover(accessToken: any, approverID: number) {
    let payload = { approverID: approverID };

    let httpOptions = {
      headers: { Authorization: `Bearer ${accessToken}` },
      body: payload,
    };
    let url = `${AppConst.baseURL}${ApiPaths.DELETE_APPROVER}`;
    return this.httpClient.delete<any>(url, httpOptions);
  }

  addApprover(accessToken: any, payload: any) {
    let url = `${AppConst.baseURL}${ApiPaths.ADD_APPROVER}`;
    return this.httpClient.post<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  reduceDatascope(accessToken: any, payload: any) {
    let url = `${AppConst.baseURL}${ApiPaths.DATASCOPE_REDUCE}`;
    return this.httpClient.post<any>(url, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  getFieldCategoryDatasource(accessToken: any) {
    let url = `${AppConst.baseURL}${ApiPaths.FIELD_CATEGORY_DATASOURCE}`;
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
}
