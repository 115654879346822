import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private notification: NzNotificationService) {}

  show(type: string, notificationMessage: string) {
    this.notification
      .create(type, type.toLocaleUpperCase(), notificationMessage, {
        nzPlacement: 'top',
      })
      .onClick.subscribe(() => {});
  }
}
