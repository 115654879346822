import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class ArraySortPipe implements PipeTransform {
  transform(array: any, field: any) {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (
        a.dataScopeName != 'Unit-ALL' &&
        b.dataScopeName != 'Unit-ALL' &&
        a.dataScopeName != 'Country-ALL' &&
        b.dataScopeName != 'Country-ALL'
      ) {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    });
    return array;
  }
}
