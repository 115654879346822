import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiPaths } from '../../../environments/environment';
import { AppConst } from '../../../assets/shared.constant';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  userType = new BehaviorSubject(true);
  baseUrl = AppConst.baseURL;
  userDetails: IUserInformation;

  constructor(private httpClient: HttpClient) {
    this.userDetails = this.getEmptyUser();
  }

  private getEmptyUser(): IUserInformation {
    return {
      name: '',
      email: '',
      isAdmin: false,
      allowedRoleNames: [],
    } as IUserInformation;
  }
  getSingedinUserDetails(accessToken: any) {
    let url = `${this.baseUrl}${ApiPaths.SINGEDIN_USER_DETAILS}`;
    return this.httpClient.get<any>(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  setUserDetails(userdetails: IUserInformation): void {
    this.userDetails = userdetails;
    sessionStorage.setItem('_u', JSON.stringify(this.userDetails));
  }

  getUserDetails(): IUserInformation {
    const s: string = sessionStorage.getItem('_u') as string;
    const user: IUserInformation = JSON.parse(s);
    if (!user) {
      return this.getEmptyUser();
    }
    return user;
  }
}

export interface IUserInformation {
  name: string;
  email: string;
  isAdmin: boolean;
  allowedRoleNames: string[];
}
