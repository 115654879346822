<nz-header class="app-header"
  ><div class="app-logo" (click)="goToHome()"></div>
  <div class="app-title">{{ app_title }}</div>
  <div class="header-user clickable">
    <ul nz-menu nzMode="horizontal" class="user-menu">
      <li nz-submenu nzTitle="{{ userName }}" nzIcon="user">
        <ul>
          <li nz-menu-item *ngIf="!userLoggedIn" (click)="login()">Login</li>
          <li nz-menu-item *ngIf="userLoggedIn" (click)="logout()">Log out</li>
        </ul>
      </li>
    </ul>
  </div>
</nz-header>
