export class AppConst {
  static baseURL: string = '/';
  static redirectURI: string = '/';
  static clientId: string = '';
  static authority: string = '';
  static scopesRead: string = '';
  static isCalled: boolean = false;
}

export class APPCONSTANT {
  baseURL: string = '/';
  redirectURI: string = '/';
  clientId: string = '';
  authority: string = '';
  scopesRead: string = '';
  isCalled: boolean = false;
}

export class URLConst {
  static production: string = 'https://atsc-auth-api-01-dev.azurewebsites.net';
  static development: string = 'https://atsc-auth-api-01-dev.azurewebsites.net';
}

export class DocumentUrl {
  static documentURL: string =
    'https://onevirtualoffice.sharepoint.com/sites/HRDataGlossary';
}
