import { NgModule } from '@angular/core';
import { RequestFormComponent } from './reusable/request-form/request-form.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ListTableComponent } from './reusable/list-table/list-table.component';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { DataCategoryModalComponent } from '../pages/requester-module/data-category-modal/data-category-modal.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { DataScopeModalComponent } from '../pages/requester-module/data-scope-modal/data-scope-modal.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { HeaderComponent } from './reusable/header/header.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { IconsProviderModule } from '../icons-provider.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ArraySortPipe } from './reusable/pipes/array-sort-pipe';

@NgModule({
  imports: [
    NzButtonModule,
    NzGridModule,
    NzDividerModule,
    NzInputModule,
    FormsModule,
    NzModalModule,
    NzUploadModule,
    NzTabsModule,
    CommonModule,
    NzTableModule,
    NzSpaceModule,
    NzAutocompleteModule,
    NzSelectModule,
    NzTagModule,
    NzLayoutModule,
    IconsProviderModule,
    NzAvatarModule,
    NzPaginationModule,
    ReactiveFormsModule,
    NzMenuModule,
    NzSpinModule,
  ],
  declarations: [
    RequestFormComponent,
    ListTableComponent,
    DataCategoryModalComponent,
    DataScopeModalComponent,
    HeaderComponent,
    ArraySortPipe,
  ],
  exports: [RequestFormComponent, ListTableComponent, HeaderComponent,ArraySortPipe],
})
export class SharedModule {}
