export const environment = {
  production: false,
};

export enum ApiPaths {
  APPLICATION_ACCESS_REQUEST_ALL = '/api/ApplicationAccessRequest/all',
  APPLICATION_ACCESS_REQUEST_PENDING = '/api/ApplicationAccessRequest/all/pendingapproval',
  APPLICATION_ACCESS_REQUEST_RENEWAL = '/api/ApplicationAccessRequest/all/renewalapproaching/days/',
  APPLICATION_ACCESS_REQUEST_COMMENTS_ALL = '/api/ApplicationAccessRequest/comment/all/requestID/',
  APPLICATION_ACCESS_REQUEST_REQUESTID = '/api/ApplicationAccessRequest/requestID/',
  GET_CMDB_AUTOCOMPLETE = '/api/CMDB/count/',
  SAVE_UPDATE_REQUEST = '/api/ApplicationAccessRequest',
  GET_FIELD_CATEGORIES = '/api/FieldCategory',
  GET_DATA_SCOPE = '/api/DataScope',
  ADD_COMMENTS = '/api/ApplicationAccessRequest/comment/requestID/',
  APPLICATION_ACCESS_NEW_REQUEST = '/api/ApplicationAccessRequest/get/new',
  SEARCH_CMDB_ID = '/api/CMDB/search/',
  GET_STATISTICS = '/api/ApplicationAccessRequest/dashboardCounters/approverEmail/',
  ADMIN_ACCESS_CONTROL_ALL_DATA = '/api/ApplicationAccessControlRole',
  ADMIN_ACCESS_CONTROL_ADD_DATA = '/api/ApplicationAccessControlRole',
  GET_APPROVER_LEVEL_DATA = '/api/ApproverLevel',
  DELETE_APPROVER_LEVEL_DATA = '/api/ApproverLevel',
  EDIT_APPROVER_LEVEL_DATA = '/api/ApproverLevel',
  GET_APPROVER_DATA_SCOPE_MAPPING = '/api/ApproverDataScopeMapping',
  DELETE_APPROVER_DATA_SCOPE_MAPPING = '/api/ApproverDataScopeMapping',
  EDIT_APPROVER_DATA_SCOPE_MAPPING = '/api/ApproverDataScopeMapping',
  ADD_APPROVER_DATA_SCOPE_MAPPING = '/api/ApproverDataScopeMapping',
  GET_APPROVAL_LEVEL_SEQUENCE = '/api/ApprovalLevelSequence',
  EDIT_APPROVAL_LEVEL_SEQUENCE = '/api/ApprovalLevelSequence',
  ADD_APPROVAL_LEVEL_SEQUENCE = '/api/ApprovalLevelSequence',
  SEARCH_EMPLOYEE = '/api/Employees/all',
  SINGEDIN_USER_DETAILS = '/api/ApplicationAccessControlRole/signedinuserdetails',
  APPROVE_REJECT_REQUEST = '/api/ApplicationAccessRequest/approval',
  GET_ALL_APPROVERS = '/api/Approver/all',
  DELETE_APPROVER = '/api/Approver',
  ADD_APPROVER = '/api/Approver',
  DATASCOPE_REDUCE = '/api/DataScope/reduce',
  FIELD_CATEGORY_DATASOURCE = '/api/FieldCategory/fieldcategorydatasource',
}
